import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-regular-svg-icons'
import {faLink} from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs';
import CategoryTags from './categoryTags';


class project extends Component {

    render() {
        let proj = this.props.project
        return <div className='project' key={'proj-' + proj.id}>

            <div className='project-star'>
                <FontAwesomeIcon icon={faStar}/>
                <p>{proj.stars.toLocaleString()}</p>
            </div>
            <div className='project-main'>
                <div className='project-title'>
                    <h4><Link
                        to={this.props.inCategory ? this.props.url : `/${proj.categories[0].awesome_list.id}-${encodeURIComponent(proj.categories[0].awesome_list.title)}/${proj.categories[0].id}-${encodeURIComponent(proj.categories[0].name)}/${proj.id}-${encodeURIComponent(proj.owner)}-${encodeURIComponent(proj.name)}`}>{proj.owner}/{proj.name}</Link>
                        <a href={proj.url} target='_blank' rel="noopener noreferrer" className={'project-gitlink'}><FontAwesomeIcon
                            icon={faLink}/></a>
                    </h4>


                    <h5 className='project-lastseen'>
                        {dayjs().from(dayjs(proj.lastCommit), true)} ago updated
                    </h5>
                </div>
                <CategoryTags categories={this.props.inCategory ? [] : proj.categories}/>
                <div className='project-description'>
                    <p>
                        {proj.description}
                    </p>
                </div>
                <div className='project-misc'>
                    <p>{proj.forks} forks・{proj.watchers} watchers・{proj.openIssues} issues</p>
                </div>
            </div>

        </div>;
    }
}

export default project;
