import React, {Component} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import config from '../config'
import Project from './project';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from 'react-helmet'
import {capitalize} from "lodash"
import NProgress from 'nprogress/nprogress.js';

class HomeCategories extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        loaded: false,
        data: {},
    };

    renderProject() {
        if (!this.state.loaded) {
            return
        }
        let data = this.state.data;
        return <div>
            <div className={this.props.type}>
                <h2 className='home-title mobile-hide'>
                    {this.props.type}
                </h2>
                <div>
                    {data.data.map(proj => {
                            return <Project project={proj}/>
                        }
                    )}
                </div>
            </div>
        </div>
    }

    scrollToTop() {
        this.myRef.current.scrollTop = 0;
    }

    renderPagination() {
        if (!this.state.loaded) {
            return
        }
        let data = this.state.data;
        let paginateItems = [];
        for (let i = 1; i <= data.last_page; i++) {

            paginateItems.push(<li><Link to={'/' + this.props.type + '/' + i}
                                         className={classNames(['pagination-link', {
                                             'is-current': i === data.current_page
                                         }])}>{i}</Link></li>)
        }
        let currentPage = parseInt(data.current_page)
        let lastPage = parseInt(data.last_page)

        let mobilePages = currentPage === 1 ? [1, 2, 3] : (lastPage === currentPage ? [lastPage - 2, lastPage - 1, lastPage] : [currentPage - 1, currentPage, currentPage + 1])
        let mobilePaginateItems = mobilePages.map(el => {
            return (el === currentPage) ? <li className={classNames(['pagination-link', 'is-current'])}>{el}</li> :

                <li><Link to={'/' + this.props.type + '/' + el}
                          className="pagination-link">{el}</Link></li>

        })
        return <nav className="pagination is-centered" role="navigation" aria-label="pagination">
            <ul className="pagination-list mobile-hide">
                {paginateItems}
            </ul>
            <ul className="pagination-list mobile-show">
                <li>
                    <Link to={'/' + this.props.type + '/' + (currentPage - 1)}
                          className="pagination-link"
                          disabled={currentPage < 2}><FontAwesomeIcon
                        icon={faAngleLeft}/></Link>
                </li>
                {mobilePaginateItems}
                <li>
                    <Link to={'/' + this.props.type + '/' + (currentPage + 1)}
                          className="pagination-link"
                          disabled={currentPage >= parseInt(data.last_page)}><FontAwesomeIcon
                        icon={faAngleRight}/></Link>
                </li>
            </ul>
        </nav>
    }

    menuOpen() {
        this.props.menuOpen()
    }

    getData() {
        let page = this.props.match.params.page ? this.props.match.params.page : 1
        NProgress.start();
        axios.get(config.api + '/' + this.props.type + '?page=' + page)
            .then((resp) => {
                this.setState({
                    data: resp.data,
                    loaded: true
                });
                window.scrollTo(0, 0);
                this.myRef.current.scrollTop = 0;
                NProgress.done();
            })
            .catch((error) => {
                console.log(error);
            })
    }


    componentDidUpdate(prevProps) {
        console.log('did update');
        if (this.props.match.params.page !== prevProps.match.params.page) {
            this.getData();
        }
        window.dispatchEvent(new Event('scroll'))
    }


    shouldComponentUpdate(nextProps, nextState) {
        return (this.state !== nextState ||
            this.props.match.params.page !== nextProps.match.params.page
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let homeClass = classNames([{
            // 'is-hidden': !this.props.match.params.projectID
        }, 'home'])
        return <div className={homeClass} id={this.props.type} ref={this.myRef}>
            <Helmet>
                <title>{capitalize(this.props.type)}{config.titleTail}</title>
            </Helmet>
            <div className="listing-heading mobile-show">
                <h1><a href={'#'} onClick={this.menuOpen.bind(this)} aria-label="Menu"><FontAwesomeIcon
                    icon={faBars}/></a>{this.props.type}</h1>
            </div>
            <div className={'home-content'}>
                {this.renderProject()}
                {this.renderPagination()}
            </div>

        </div>
    }
}

export default HomeCategories;
