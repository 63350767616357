import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleLeft, faAngleRight, faBars} from "@fortawesome/free-solid-svg-icons";
import Project from './project';
import classNames from "classnames"
import config from '../config'
import {Helmet} from "react-helmet";
import NProgress from 'nprogress/nprogress.js';

class CategoryDetails extends Component {
    state = {
        data: {},
        method: 'stars',
        page: 1,
        cateChange: false
    };

    renderProjects() {
        if (!Object.keys(this.state.data).length) {
            return null;
        }
        let projects = [];
        this.state.data.data.forEach(proj => {
            projects.push(
                <Project key={"proj-" + proj.id} ref={'project-details-' + proj.id} project={proj} inCategory={true}
                         url={"/" + this.props.match.params.listingID + "/" + this.props.match.params.subCategoryID + "/" + proj.id + "-" + proj.owner + "-" + proj.name}/>)
        })
        return projects
    }

    onPageChange(event) {
        let page = event.currentTarget.getAttribute('data-page')

        if (event.currentTarget.getAttribute('data-page')) {
            this.setState({
                page: parseInt(page)
            })
        } else {
            if(event.currentTarget.getAttribute('disabled')!==null)
                return;
            let isNextPage = event.currentTarget.getAttribute('data-pageturn');
            if (isNextPage==='1') {
                this.setState({
                    page: parseInt(this.state.page)+1
                })
            } else {
                this.setState({
                    page: parseInt(this.state.page)-1
                })
            }
        }


    }

    renderPagination() {
        let currentPage = parseInt(this.state.data.current_page)
        let lastPage = parseInt(this.state.data.last_page)
        if (isNaN(currentPage) || (currentPage === lastPage && currentPage === 1))
            return null;
        /*
        let mobilePages = currentPage === 1 ? [1, 2, 3] : (lastPage === currentPage ? [lastPage - 2, lastPage - 1, lastPage] : [currentPage - 1, currentPage, currentPage + 1])
        let mobilePaginateItems = mobilePages.map(el => {
            return (el === currentPage) ? <li className={classNames(['pagination-link', 'is-current'])}>{el}</li> :

                <li><a onClick={this.onPageChange.bind(this)}
                       className="pagination-link" data-page={el}>{el}</a></li>

        })*/

        return <nav className="pagination is-centered project" role="navigation" aria-label="pagination">
            <ul className="pagination-list">
                <li>
                    <a onClick={this.onPageChange.bind(this)}
                       className="pagination-link"
                       disabled={currentPage < 2} data-pageturn={0}><FontAwesomeIcon
                        icon={faAngleLeft}/></a>
                </li>
                <li className={classNames(['pagination-link', 'is-current'])}>{currentPage}</li>
                <li>
                    <a onClick={this.onPageChange.bind(this)}
                       className="pagination-link"
                       disabled={currentPage >= parseInt(this.state.data.last_page)}
                       data-pageturn={1}><FontAwesomeIcon
                        icon={faAngleRight}/></a>
                </li>
            </ul>
        </nav>
    }

    getData(listChange = false) {
        let subCategoryID = this.props.match.params.subCategoryID.match(/[\d]+/)
        NProgress.start();
        let url = config.api + '/awesomeList/' + subCategoryID[0] + '/items'
        if (subCategoryID[0] === '0') {
            url = config.api + '/awesomeList/' + this.props.match.params.listingID.match(/[\d]+/) + '/all'
        }
        let sort = listChange ? 'name' : this.state.method;
        let page = listChange || this.state.cateChange ? 1 : this.state.page;
        axios.get(url, {
            params: {
                sort: sort,
                page: page
            }
        })
            .then((resp) => {
                this.setState({
                    sort: sort,
                    page: page,
                    data: resp.data,
                    cateChange: false
                });
                if (subCategoryID[0] === '0') {
                    this.props.history.push(`/${this.props.match.params.listingID}/${subCategoryID}-${encodeURIComponent(this.props.subCategory)}--page${page}--sortBy-${sort}/`)
                }
                // this.self.current.scrollTop = 0
                if (window.innerWidth < 1024 || this.state.page !== 1) {
                    window.scrollTo(0, 0)
                }
                NProgress.done();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    menuOpen() {
        this.props.menuOpen()
    }

    selectChange(event) {
        this.setState({method: event.target.value, cateChange: true});
        // this.getData(event.target.value)
    }

    componentDidUpdate(prevProps, prevState) {
        let subCategoryID = (this.props.match.params.subCategoryID.match(/[\d]+/))[0];
        let prevID = (prevProps.match.params.subCategoryID.match(/[\d]+/))[0];
        if (subCategoryID !== prevID || prevState.method !== this.state.method || prevState.page !== this.state.page) {
            this.getData(subCategoryID !== prevID);
        }
        if (this.props.location.state && this.props.location.projectID) {
            this.scrollToRef('project-details-' + this.props.location.projectID)
        }
        window.dispatchEvent(new Event('scroll'))
    }

    scrollToRef = (ref) => ref.current && window.scrollTo(0, ref.current.offsetTop)

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state.data !== nextState.data ||
            this.props.match.params.subCategoryID !== nextProps.match.params.subCategoryID ||
            this.props.match.params.projectID !== nextProps.match.params.projectID ||
            this.props.mainCategory !== nextProps.mainCategory ||
            this.props.viewMore !== nextProps.viewMore ||
            this.state.method !== nextState.method ||
            this.state.page !== nextState.page
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }

    componentWillMount() {
        /**
         * read path as params
         */
        let history = this.props.match.params.subCategoryID.match(/--page([\d]+)--sortBy-([a-zA-Z]+)/);
        if (history) {
            this.setState({
                page: history[1],
                sort: history[2]
            })
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let categoryClass = classNames(['category-listing', {'is-hidden': this.props.match.params.projectID}])

        return [
            <div className={categoryClass}>
                <Helmet>
                    <title>{this.props.mainCategory + "-" + this.props.subCategory + config.titleTail}</title>
                </Helmet>
                <div className="listing-heading">
                    <h1><a href='#' className={'mobile-show'} onClick={this.menuOpen.bind(this)}
                           aria-label="Menu"><FontAwesomeIcon
                        icon={faBars}/></a>{this.props.mainCategory}</h1>
                </div>
                <div className="listing-subheading" ref={this.self}>
                    <h2>
                        {this.props.subCategory} <span className='mobile-show more'><Link
                        to={{
                            pathname: "/" + this.props.match.params.listingID + "/",
                            state: {previousID: this.props.match.params.subCategoryID}
                        }}>More</Link></span>
                    </h2>
                    <div className={"select-wrapper mobile-hide"}>
                        <span className="sort-label">Sort by: </span>
                        <div className="select">
                            <select onChange={this.selectChange.bind(this)} value={this.state.value}
                                    defaultValue={'stars'}>
                                <option value={'stars'}>Stars</option>
                                <option value={'name'}>Name</option>
                                <option value={'lastCommit'}>Update</option>
                            </select>
                        </div>
                    </div>
                    {(this.props.subCategoryDesc && this.props.subCategoryDesc.indexOf('](')<0) ?<h6>{this.props.subCategoryDesc}</h6>:''}
                </div>
                <div className="listing-body">
                    <div className={'project mobile-sort'}>
                        <span className="sort-label">Sort by: </span>
                        <div className="select">
                            <select onChange={this.selectChange.bind(this)} value={this.state.value}
                                    defaultValue={'stars'}>
                                <option value={'stars'}>Stars</option>
                                <option value={'name'}>Name</option>
                                <option value={'lastCommit'}>Update</option>
                            </select>
                        </div>
                    </div>
                    {this.renderProjects()}
                    {this.renderPagination()}
                </div>
            </div>
        ];
    }
}

export default CategoryDetails;
