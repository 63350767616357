import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from 'axios';
import config from '../config'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons'
import classNames from "classnames"

class SideBar extends Component {
    state = {
        data: [],
        success: false,
        search: ''
    };

    createMainMenu() {
        let items = ['home', 'popular', 'new', 'updated'];
        return <ul>
            {items.map(el => {
                let itemClass = {
                    'is-active': this.props.match.params.listingID === el || (el === 'home' && this.props.match.url === "/")
                }
                return <li key={'sidebar-main-' + el}>
                    <Link to={"/" + (el === 'home' ? '' : el)}
                        // className={listingID === e.id ? 'is-active' : ''}
                          key={'home-a-' + el} className={classNames(itemClass)} onClick={this.menuClose.bind(this)}
                          aria-label="Home Category"
                    >{el}</Link>
                </li>
            })}
        </ul>
    }

    menuClose() {
        console.log('menu closed by sidebar')
        this.props.menuOpen(false)
    }

    menuOpen() {
        this.props.menuOpen()
    }

    createMenu() {
        let menu = [];
        const {params} = this.props.match;
        let listingID = parseInt(params.listingID);
        this.state.data.forEach(cate => {
            let subList = cate.lists.map(e => {
                if (e.master)
                    return null;
                if (listingID === e.id)
                    this.props.categoryUpdate({
                        mainCategory: e.title
                    })

                let searchPass = false;
                if (e.title.toLowerCase().includes(this.state.search.toLowerCase()))
                    searchPass = true
                let childItems = e.list ?
                    <div className={"sub-cat"}> {e.list.map(f => {
                        let localSearchPass = false
                        if (f.title.toLowerCase().includes(this.state.search.toLowerCase())) {
                            searchPass = true;
                            localSearchPass = true;
                        }

                        if (listingID === f.id)
                            this.props.categoryUpdate({
                                mainCategory: f.title
                            })
                        return (this.state.search.length === 0 || localSearchPass) ?
                            <Link className={listingID === f.id ? 'is-active' : ''}
                                to={listingID === f.id ? "#" : "/" + f.id + "-" + encodeURIComponent(f.title)}
                                onClick={this.menuClose.bind(this)}
                                key={'awesome-a-' + f.id}
                                aria-label={f.title}>{f.title} ({f.count})</Link>
                            : ''
                    })} </div> : '';
                if (this.state.search.length === 0 || searchPass) {
                    return <><Link
                        to={listingID === e.id ? "#" : "/" + e.id + "-" + encodeURIComponent(e.title)}
                        className={listingID === e.id ? 'is-active' : ''}
                        key={'awesome-a-' + e.id}
                        onClick={this.menuClose.bind(this)}
                        aria-label={e.title}

                    >{e.title} ({e.count})</Link>{childItems}</>
                }
                return null
            });
            //<p className="menu-label" key={"cate-label-" + cate.id}>
            //                 {cate.name}
            //             </p>
            menu.push(<div className="" key={"cate-" + cate.id}>{subList}</div>);
        })

        return menu;
    }

    handleSearch(event) {
        this.setState({
            search: event.target.value
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state.data !== nextState.data ||
            this.props.match.params.listingID !== nextProps.match.params.listingID ||
            this.state.search !== nextState.search
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }

    componentDidMount() {
        axios.get(config.api + '/awesomeListWithCategory')
            .then((resp) => {
                // console.log(resp);
                resp.data.forEach(cate => {
                    cate.lists.forEach((item, index, object) => {
                        let self = item;
                        if (self.master) {
                            let master = cate.lists.find(function (element) {
                                return element.id === self.master
                            })
                            if (master) {
                                if (master.list) {
                                    master.list.push(self)
                                } else {
                                    master.list = [self]
                                }
                            }
                        }
                        // delete cate.lists[index];
                    })
                })
                this.setState({
                    data: resp.data,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        // const {onChange} = this.props;
        return [<div className="mobile-show sidebar-sticky">
            <img className="logo" src="/logo.png" alt="logo"/>
            <a href={'#'} onClick={this.menuClose.bind(this)} aria-label="Menu"><FontAwesomeIcon icon={faTimes}/></a>
        </div>, <div className={classNames(['sidebar'])}>

            <div className="sidebar-top">
                <div className="search control has-icons-right">
                    <input className="input" type="text" placeholder="Search" onChange={this.handleSearch.bind(this)}/>
                    <span className="icon is-small is-right">
                        <FontAwesomeIcon icon={faSearch}/>
                        </span>
                </div>
                <div className="sidebar-categories">
                    {this.createMainMenu()}
                </div>
                <hr/>
                {/* <div className="sidebar-categories">
                    <ul>
                        <li key={'sidebar-main-discuss'}>
                            <Link to={"/discuss"}
                                  className={this.props.match.params.listingID === 'discuss' ? 'is-active' : ''}
                                  onClick={this.menuClose.bind(this)}
                                  aria-label="Discuss"
                            >Discuss</Link>
                        </li>
                    </ul>
                </div>
                <hr/> */}
            </div>
            <aside className="menu">
                {this.createMenu()}
            </aside>
        </div>];
    }
}

export default SideBar;
