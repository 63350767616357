import React, {Component} from 'react';
import './App.sass';
import './components/sidebar'
import {BrowserRouter, Route, Link} from "react-router-dom";
import SideBar from "./components/sidebar";
import CategoryListing from "./components/categorylisting";
import ProjectDetails from "./components/projectdetails";
import Home from "./components/home";
import HomeCategories from "./components/homecategories";
import CategoryDetails from "./components/categorydetails";
import Discuss from "./components/discuss";
import {StickyContainer, Sticky} from './react-sticky/src';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import classNames from "classnames";
import GAListener from "./components/galistener";
import NProgress from 'nprogress/nprogress.js';

dayjs.extend(relativeTime);

class App extends Component {
    state = {
        mainCategory: '',
        subCategory: '',
        homeTitle: '',
        menuOpen: false
    }
    categoryUpdate = (dataFromChild) => {
        // console.log(dataFromChild);
        this.setState(Object.assign({}, this.state, dataFromChild));
    }
    menuOpen = (open = true) => {
        if (this.state.menuOpen !== open)
            this.setState(Object.assign({}, this.state, {
                menuOpen: open
            }));
    }

    render() {
        this.stickyContainer = React.createRef()
        NProgress.configure({ showSpinner: false });
        return (
            <BrowserRouter>
                <GAListener trackingId={'UA-120006495-11'}>
                    <div className="">
                        <header>
                            <div className="header-container">
                                <div>
                                    <Link to={'/'}>
                                        <img className="logo" src="/logo.png" alt="Awesome Lists" width="222" height="32" />
                                    </Link>
                                </div>
                                {/*<div className="header-items">*/}
                                {/*    <span className="header-link"><b>Submit awesome work</b></span>*/}
                                {/*</div>*/}
                            </div>
                        </header>
                        <div id="awesome" className="container" ref={this.stickyContainer}>
                            <StickyContainer>
                                <div className={classNames(['left-col', {
                                    'is-hidden-mobile': !this.state.menuOpen,
                                    // 'is-shown': this.state.menuOpen
                                }])}>
                                    <Sticky relative={true} topOffset={32} bottomOffset={32}>
                                        {({
                                              style,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Route path={["/:listingID", "/*"]} render={
                                                    routeProps => <SideBar
                                                        key={'sidebar'}  {...Object.assign({}, routeProps, {
                                                        categoryUpdate: this.categoryUpdate,
                                                        menuOpen: this.menuOpen
                                                    })} />
                                                }/>
                                            </div>
                                        )}
                                    </Sticky>
                                </div>
                                <Route exact path="/" render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32}
                                                          className={classNames([{
                                                              'is-hidden-mobile': this.state.menuOpen,
                                                          }, 'right-full-col'])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Home {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    menuOpen: this.menuOpen
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>
                                <Route exact path="/discuss" render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32} className={classNames([{
                                        'is-hidden-mobile': this.state.menuOpen,
                                    }, 'right-full-col'])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Discuss {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    menuOpen: this.menuOpen
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>
                                <Route path={['/popular/:page', '/popular']} render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32}
                                                          className={classNames(['right-full-col', {
                                                              'is-hidden-mobile': this.state.menuOpen
                                                          }])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <HomeCategories {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    menuOpen: this.menuOpen,
                                                    stickyContainer: this.stickyContainer,
                                                    type: 'popular'
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>
                                <Route path={['/new/:page', '/new']} render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32}
                                                          className={classNames(['right-full-col', {
                                                              'is-hidden-mobile': this.state.menuOpen
                                                          }])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <HomeCategories {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    menuOpen: this.menuOpen,
                                                    type: 'new'
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>
                                <Route exact path={['/trending/:page', '/trending']} render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32}
                                                          className={classNames(['right-full-col', {
                                                              'is-hidden-mobile': this.state.menuOpen
                                                          }])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <HomeCategories {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    menuOpen: this.menuOpen,
                                                    type: 'trending'
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>
                                <Route exact path={['/updated/:page', '/updated']} render={
                                    routeProps => <Sticky topOffset={32} bottomOffset={32}
                                                          className={classNames(['right-full-col', {
                                                              'is-hidden-mobile': this.state.menuOpen
                                                          }])}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <HomeCategories {...Object.assign({}, routeProps, {
                                                    categoryUpdate: this.categoryUpdate,
                                                    type: 'updated'
                                                })} />
                                            </div>
                                        )}
                                    </Sticky>
                                }/>

                                <Route
                                    path={["/:listingID([\\d]+[^\\/]+)/:subCategoryID/:projectID", "/:listingID([\\d]+[^\\/]+)/:subCategoryID", "/:listingID([\\d]+[^\\/]+)"]}
                                    render={
                                        routeProps => <div className={classNames(['mid-col', {
                                            'is-hidden-mobile': routeProps.match.params.subCategoryID
                                        }])}>
                                            <Sticky topOffset={32} bottomOffset={32}>
                                                {({
                                                      style,

                                                      // the following are also available but unused in this example
                                                      isSticky,
                                                      wasSticky,
                                                      distanceFromTop,
                                                      distanceFromBottom,
                                                      calculatedHeight
                                                  }) => (
                                                    <div style={style}>
                                                        <CategoryListing
                                                            key={'category-listing'} {...Object.assign({}, routeProps, {
                                                            mainCategory: this.state.mainCategory,
                                                            categoryUpdate: this.categoryUpdate,
                                                            menuOpen: this.menuOpen
                                                        })} />
                                                    </div>
                                                )}
                                            </Sticky>
                                        </div>
                                    }
                                />

                                <div className={classNames(['right-col', {
                                    'is-hidden-mobile': this.state.menuOpen
                                }])} key={'category-listing'}>
                                    <Sticky topOffset={32} bottomOffset={32}>
                                        {({
                                              style,

                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Route
                                                    path={["/:listingID([\\d]+[^\\/]+)/:subCategoryID/:projectID", "/:listingID([\\d]+[^\\/]+)/:subCategoryID/"]}
                                                    render={routeProps =>
                                                        <CategoryDetails {...Object.assign({}, routeProps, {
                                                            mainCategory: this.state.mainCategory,
                                                            subCategory: this.state.subCategory,
                                                            subCategoryDesc: this.state.subCategoryDesc,
                                                            menuOpen: this.menuOpen
                                                        })} />}
                                                />
                                            </div>
                                        )}
                                    </Sticky>
                                    <Sticky topOffset={32} bottomOffset={32}>
                                        {({
                                              style,
                                              forceStickyStyle,
                                              // the following are also available but unused in this example
                                              isSticky,
                                              wasSticky,
                                              distanceFromTop,
                                              distanceFromBottom,
                                              calculatedHeight
                                          }) => (
                                            <div style={style}>
                                                <Route
                                                    path={["/:listingID/:subCategoryID/:projectID"]}
                                                    render={routeProps =>
                                                        <ProjectDetails {...Object.assign({}, routeProps, {
                                                            mainCategory: this.state.mainCategory,

                                                        })} />}
                                                />
                                            </div>
                                        )}
                                    </Sticky>
                                </div>

                            </StickyContainer>
                        </div>
                    </div>
                </GAListener>
            </BrowserRouter>
        );
    }
}

export default App;
