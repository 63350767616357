import React, {Component} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class Sticky extends Component {
    static propTypes = {
        topOffset: PropTypes.number,
        bottomOffset: PropTypes.number,
        relative: PropTypes.bool,
        children: PropTypes.func.isRequired
    };

    static defaultProps = {
        relative: false,
        topOffset: 0,
        bottomOffset: 0,
        disableCompensation: false,
        disableHardwareAcceleration: false,
        className: '',
        mobile: 1024
    };

    static contextTypes = {
        subscribe: PropTypes.func,
        unsubscribe: PropTypes.func,
        getParent: PropTypes.func
    };

    state = {
        isSticky: false,
        wasSticky: false,
        top: 0,
        distanceFromTop: 0,
        style: {},
    };

    componentWillMount() {
        if (!this.context.subscribe)
            throw new TypeError(
                "Expected Sticky to be mounted within StickyContainer"
            );

        this.context.subscribe(this.handleContainerEvent);
    }

    componentWillUnmount() {
        this.context.unsubscribe(this.handleContainerEvent);
    }

    componentDidUpdate() {
        this.placeholder.style.paddingBottom = this.props.disableCompensation || window.innerWidth < this.props.mobile
            ? 0
            : `${this.state.isSticky ? this.state.calculatedHeight : 0}px`;
    }

    handleContainerEvent = ({
                                distanceFromTop,
                                distanceFromBottom,
                                eventSource
                            }) => {
        if (window.innerWidth < this.props.mobile) {
            this.setState({
                style: {}
            })
            return
        }
        const parent = this.context.getParent();

        let preventingStickyStateChanges = false;
        if (this.props.relative) {
            preventingStickyStateChanges = eventSource !== parent;
            distanceFromTop =
                -(eventSource.scrollTop + eventSource.offsetTop) +
                this.placeholder.offsetTop;
        }

        const placeholderClientRect = this.placeholder.getBoundingClientRect();
        const contentClientRect = this.content.getBoundingClientRect();
        const calculatedHeight = contentClientRect.height;

        // const bottomDifference =
        //     distanceFromBottom - this.props.bottomOffset - calculatedHeight;

        const wasSticky = !!this.state.isSticky;
        const isSticky = preventingStickyStateChanges
            ? wasSticky
            : distanceFromTop <= -this.props.topOffset &&
            distanceFromBottom > -this.props.bottomOffset;

        distanceFromBottom =
            (this.props.relative
                ? parent.scrollHeight - parent.scrollTop
                : distanceFromBottom) - calculatedHeight;
        const scrollHeight = parent.scrollHeight;
        const scrollTop = parent.scrollTop;
        let top;
        let bottom = 'unset';
        if (calculatedHeight < window.innerHeight) {
            top = this.props.topOffset
        } else {
            if (distanceFromTop < this.state.distanceFromTop) {
                //scroll down
                // console.log('scroll down')

            } else if (distanceFromTop > this.state.distanceFromTop) {
                //scroll up
                bottom = 'unset';
                // console.log('scrolled up')
            }
            let scrollDistance = distanceFromTop - this.state.distanceFromTop
            let parsedTop =
                parseInt(this.state.top);
            let newTop = (isNaN(parsedTop) ? -(calculatedHeight - window.innerHeight) : parsedTop) + scrollDistance
            if (newTop < -(calculatedHeight - window.innerHeight) + this.props.bottomOffset) {
                bottom = this.props.bottomOffset
                top = 'unset'
            } else {
                if (newTop > this.props.topOffset)
                    top = this.props.topOffset
                else
                    top = newTop
            }
        }

        // console.log(top);
        /*
        if (distanceFromTop < this.state.distanceFromTop) {
            // console.log('scrolled down')
        } else if (distanceFromTop > this.state.distanceFromTop) {
            // console.log('scrolled up')
        }
        parseInt(this.state.top);
        let parsedTop =
            parseInt(this.state.top);
        const newTop = (isNaN(parsedTop) ? (calculatedHeight - window.innerHeight) : parsedTop) + (distanceFromTop - this.state.distanceFromTop)
        const top = newTop <= -(calculatedHeight - window.innerHeight) ? 'unset' : (newTop > 0 ? 0 : newTop);
        const bottom = newTop < -(calculatedHeight - window.innerHeight) ? 0 : 'unset';
        console.log(top, 'current top');*/
        const style = !isSticky
            ? {}
            : {
                position: "fixed",
                top,
                bottom,
                // 0,
                /*bottomDifference > 0
                  ? this.props.relative
                    ? parent.offsetTop - parent.offsetParent.scrollTop
                    : 0
                  : bottomDifference,*/
                left: placeholderClientRect.left,
                width: placeholderClientRect.width
            };
        const forceStickyStyle = {
            position: "fixed",
            top,
            bottom,
            // 0,
            /*bottomDifference > 0
              ? this.props.relative
                ? parent.offsetTop - parent.offsetParent.scrollTop
                : 0
              : bottomDifference,*/
            left: placeholderClientRect.left,
            width: placeholderClientRect.width
        }
        if (!this.props.disableHardwareAcceleration) {
            style.transform = "translateZ(0)";
        }

        this.setState({
            isSticky,
            forceStickyStyle,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            scrollHeight,
            scrollTop,
            calculatedHeight,
            style,
            top,
            bottom
        });
    };

    render() {
        const element = React.cloneElement(
            this.props.children({
                isSticky: this.state.isSticky,
                wasSticky: this.state.wasSticky,
                distanceFromTop: this.state.distanceFromTop,
                distanceFromBottom: this.state.distanceFromBottom,
                calculatedHeight: this.state.calculatedHeight,
                style: this.state.style
            }),
            {
                ref: content => {
                    this.content = ReactDOM.findDOMNode(content);
                }
            }
        );

        return (
            <div className={this.props.className}>
                <div ref={placeholder => (this.placeholder = placeholder)}/>
                {element}
            </div>
        );
    }
}
