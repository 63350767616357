import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faLink} from '@fortawesome/free-solid-svg-icons';
import classNames from "classnames";
import axios from 'axios';
import config from '../config'
import {faStar} from "@fortawesome/free-regular-svg-icons";
import dayjs from 'dayjs';
import marked from 'marked';
import CategoryTags from './categoryTags';
import NProgress from 'nprogress/nprogress.js';
import {Helmet} from "react-helmet";

class ProjectDetails extends Component {
    state = {
        data: null,
        issues: [],
        readme: null
    };

    renderProject() {
        if (!this.state.data) {
            return
        }
        window.dispatchEvent(new Event('scroll'))
        let proj = this.state.data
        let projPayload = this.state.payload
        return <div className='project-detail' key={'proj-detail-' + proj.id}>
            <Helmet>
                <title>{proj.owner}/{proj.name}{config.titleTail}</title>
                <meta name="description" content={proj.description + config.titleTail}/>

            </Helmet>
            <div className='project-header'>
                <div className='project-header-left'>
                    <a href={proj.url} target='_blank' rel="noopener noreferrer"><h2>{proj.owner} / {proj.name}
                        <span className={'project-gitlink'}><FontAwesomeIcon icon={faLink}/></span></h2></a>
                    <div className="project-tags">
                        <CategoryTags categories={proj.categories}/>
                        <div className='project-star mobile-show'>
                            <FontAwesomeIcon icon={faStar}/>
                            <span>{proj.stars.toLocaleString()}</span>
                        </div>

                    </div>
                    <p className="project-description">{proj.description}</p>
                    <p className='project-misc'>{proj.forks} forks・{proj.watchers} watchers・{proj.openIssues} issues</p>
                </div>
                <span className='project-star mobile-hide'>
                    <FontAwesomeIcon icon={faStar}/>
                    <span>{proj.stars.toLocaleString()}</span>
                </span>
            </div>
            <div className='project-time'>
                <div className='time-block'>
                    <h5 className='time-heading'>
                        PUSHED
                    </h5>
                    <h3 className='time-content'>
                        {dayjs().from(dayjs(projPayload.pushed_at), true)} AGO
                    </h3>
                </div>
                <div className='time-block'>
                    <h5 className='time-heading'>
                        UPDATED
                    </h5>
                    <h3 className='time-content'>
                        {dayjs().from(dayjs(projPayload.updated_at), true)} AGO
                    </h3>
                </div>
                <div className='time-block'>
                    <h5 className='time-heading'>
                        CREATED
                    </h5>
                    <h3 className='time-content'>
                        {dayjs().from(dayjs(projPayload.created_at), true)} AGO
                    </h3>
                </div>
            </div>
            <div className='project-issues'>
                <h5 className='project-issues-title'>
                    Latest issues
                </h5>
                {this.state.issues.map((issue, index) => {
                    if (index > 2)
                        return null
                    return <div className="project-issue">
                        <img src={issue.user.avatar_url} alt='avatar'/>
                        <a href={issue.html_url} target='_blank' rel="noopener noreferrer">
                            <div className='project-issue-content'>
                                <h6 className='project-issue-title'>{issue.title}</h6>
                                <p className='project-issue-misc'>{dayjs().from(dayjs(issue.created_at), true)} ago
                                    ・{issue.state}・{issue.comments} {issue.comments < 2 ? ' reply' : ' replies'}</p>
                            </div>
                        </a>
                    </div>
                })}

            </div>

            <div className='project-readme content'
                 dangerouslySetInnerHTML={{__html: this.state.readme ? (this.state.is_readme_html ? this.state.readme : marked(this.state.readme)) : ''}}>

            </div>
            <div className='project-bottom-padding'></div>
        </div>
    }

    getData() {
        if (!this.props.match.params.projectID)
            return;

        let projectID = this.props.match.params.projectID.match(/[\d]+/);
        NProgress.start();
        axios.get(config.api + '/project/' + projectID)
            .then((resp) => {
                let projPayload = JSON.parse(resp.data.payload)
                let issues = JSON.parse(resp.data.issues)
                let readme = resp.data.readme

                this.setState({
                    data: resp.data,
                    payload: projPayload,
                    issues: issues,
                    readme: readme
                });
                NProgress.done();
                return;

                /*

                axios.get(projPayload.url + '/issues?per_page=3').then((respIssues) => {
                    this.setState({
                        issues: respIssues.data
                    });
                })


                axios.get(projPayload.url + '/readme').then((respMDURL) => {
                    if (respMDURL.data.download_url) {
                        axios.get(respMDURL.data.download_url).then(respMD => {
                            this.setState({
                                readme: respMD.data
                            });
                        })
                    }
                })*/
            })
            .catch((error) => {
                console.log(error);
            })

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.projectID !== prevProps.match.params.projectID) {
            this.getData();
        }
        window.dispatchEvent(new Event('scroll'))
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state !== nextState ||
            this.props.match.params.projectID !== nextProps.match.params.projectID ||
            this.props.mainCategory !== nextProps.mainCategory
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        let projectClass = classNames([{
            'is-hidden': !this.props.match.params.projectID
        }, 'detail-listing'])
        return <div className={projectClass}>

            <div className="listing-heading">
                <h1><Link
                    to={{
                        pathname: "/" + this.props.match.params.listingID + "/" + this.props.match.params.subCategoryID,
                        state: {
                            projectID: this.props.match.params.projectID
                        }
                    }}><FontAwesomeIcon
                    icon={faChevronLeft}/></Link>{this.props.mainCategory}</h1>
            </div>
            <div className="listing-body">
                {this.renderProject()}
            </div>
        </div>
    }
}

export default ProjectDetails;
