import React, {Component} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import config from "../config";
import {Helmet} from "react-helmet";


class discuss extends Component {
    menuOpen() {
        this.props.menuOpen()
    }

    componentDidMount() {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    }

    render() {

        return <div className='home'>
            <Helmet>
                <title>Discuss{config.titleTail}</title>
            </Helmet>
            <div className="listing-heading mobile-show">
                <h1><a href={'#'} onClick={this.menuOpen.bind(this)} aria-label="Menu"><FontAwesomeIcon
                    icon={faBars}/></a>Discuss</h1>
            </div>
            <div className='home-content'>
                <h2 className="home-title mobile-hide">Discuss</h2>
                <p className="discuss-content">
                    awesomelists.net is a baby and we are growing fast. You can participate too! Give us feedback down
                    below.
                </p>
                <div className="fb-comments" data-href="http://awesomelists.net/discuss" data-width="100%"
                     id="fb-discuss"
                     data-numposts="5">
                    Loading....
                </div>
            </div>
        </div>;
    }
}

export default discuss;
