import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from 'axios';
import config from '../config'
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";

class CategoryListing extends Component {
    state = {};

    unflatten(array, parent, tree) {
        let self = this;
        tree = typeof tree !== 'undefined' ? tree : [];
        parent = typeof parent !== 'undefined' ? parent : {id: 0};

        var children = array.filter(function (child) {
            return child.parent_id === parent.id;
        });

        if (children.length) {
            if (!parent.id) {
                tree = children;
            } else {
                parent['list'] = children;
            }
            children.forEach(function (child) {
                self.unflatten(array, child)
            });
        }
        return tree;
    }

    createMenu() {
        let menu = []
        if (this.state.data) {
            for (let listItem of this.state.data) {
                menu.push(this.createSubMenu(listItem))
            }
        }
        return <div className="">{menu}</div>;
    }

    menuOpen() {
        this.props.menuOpen()
        // this.props.menuOpen();
    }

    createSubMenu(list) {
        let menu = []
        // console.log(list);
        // menu.push(<a key={"a-" + list.id} >{list.name}</a>);
        // const {params} = this.props.match
        let active = false
        if (parseInt(this.props.match.params.subCategoryID) === list.id || (this.props.location.state && parseInt(this.props.location.state.previousID) === list.id)) {
            this.props.categoryUpdate({
                subCategory: list.name,
                subCategoryDesc: list.desc
                // subCategoryDesc: list.desc
            })
            active = true
        }

        menu.push(<Link key={"sub-menu-a-" + list.id}
                        to={"/" + this.props.match.params.listingID + "/" + list.id + "-" + encodeURIComponent(list.name)}
                        className={classNames({'is-active': active})}
                        onClick={evt => this.props.categoryUpdate({
                            subCategory: list.name,
                            subCategoryDesc: list.desc
                        })}
        >{list.name} ({list.count})</Link>);
        
        return menu;
        // return <li key={"sub-menu-li-" + list.id}
        //            className={classNames({'is-active': active})}>{menu}</li>;
    }

    getData() {
        let listingID = this.props.match.params.listingID.match(/[\d]+/)
        axios.get(config.api + '/awesomeList/' + listingID)
            .then((resp) => {
                this.setState({
                    data: resp.data,
                });
                if(!this.props.match.params.subCategoryID){
                    this.props.history.push(`/${this.props.match.params.listingID}/${resp.data[0].id}-${encodeURIComponent(resp.data[0].name)}/`)
                }
                if(window.innerWidth<1024){
                    window.scrollTo(0, 0)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.state) {
            console.log(this.props.location.state.previousID)
        }

        if (this.props.match.params.listingID !== prevProps.match.params.listingID) {
            this.getData();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state.data !== nextState.data || this.props.match.params.subCategoryID !== nextProps.match.params.subCategoryID ||
            this.props.match.params.listingID !== nextProps.match.params.listingID || this.props.mainCategory !== nextProps.mainCategory
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }

    componentDidMount() {
        this.getData();
    }

    render() {

        return [
            <div className="sidebar">
                <Helmet>
                    <title>{this.props.mainCategory + config.titleTail}</title>
                </Helmet>
                <div className="listing-heading mobile-show">
                    <h1><a href={'#'} onClick={this.menuOpen.bind(this)} aria-label="Menu"><FontAwesomeIcon
                        icon={faBars}/></a>{this.props.mainCategory}</h1>
                </div>
                <aside className="menu">
                    {this.createMenu()}
                </aside>
            </div>
        ];
    }
}

export default CategoryListing;
