import React, {Component} from "react";
import dragscroll from 'dragscroll';
import {Link} from "react-router-dom";

class CategoryTags extends Component {
    componentDidMount() {
        dragscroll.reset()
    }

    render(){
        return <p className='category-tags dragscroll'>
            { this.props.categories.map(cate => {
                if (cate.awesome_list && cate.awesome_list.awesome_list) {
                    return [<span className='category-list'><Link
                        to={`/${cate.awesome_list.awesome_list.id}-${encodeURIComponent(cate.awesome_list.awesome_list.title)}`}>{cate.awesome_list.awesome_list.title}</Link></span>,
                        <span className='category-sublist'><Link
                            to={`/${cate.awesome_list.id}-${encodeURIComponent(cate.awesome_list.title)}`}>{cate.awesome_list.title}</Link></span>,
                        <span className='category-type'><Link
                            to={`/${cate.awesome_list.id}-${encodeURIComponent(cate.awesome_list.title)}/${cate.id}-${encodeURIComponent(cate.name)}`}>{cate.name}</Link></span>]
                }
                return [<span className='category-list'><Link
                    to={`/${cate.awesome_list.id}-${encodeURIComponent(cate.awesome_list.title)}`}>{cate.awesome_list.title}</Link></span>,
                    <span className='category-type'><Link
                        to={`/${cate.awesome_list.id}-${encodeURIComponent(cate.awesome_list.title)}/${cate.id}-${encodeURIComponent(cate.name)}`}>{cate.name}</Link></span>]
            })}
        </p>
    }
}


export default CategoryTags;
