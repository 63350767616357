import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from "classnames";
import config from '../config'
import axios from 'axios';
import {faStar} from "@fortawesome/free-regular-svg-icons";
import dayjs from 'dayjs';
import Project from './project';
import CategoryTags from './categoryTags';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import NProgress from 'nprogress/nprogress.js';
class Home extends Component {
    state = {
        loaded: false,
        data: {
            recent: [],
            random: [],
            popular: [],
            new: [],
            trending: [],
            updated: []
        },
    };

    renderProject() {
        if (!this.state.loaded) {
            return
        }
        let data = this.state.data;
        return <div className='home-content'>
            <div className='recently-updated'>
                <h2 className='home-title' onClick={() => this.LoadingBar.continousStart()}>
                    Recently updated
                </h2>
                <div className='recent-blocks'>

                    {data.recent.map(proj => {
                        return <div className='recent-block'>
                            <Link to={`/${proj.categories[0].awesome_list.id}/${proj.categories[0].id}/${proj.id}`}>
                                <h3>
                                    {proj.owner}/{proj.name}
                                </h3></Link>
                            <div className='recent-misc'>
                                <span className='recent-star'>
                                <FontAwesomeIcon icon={faStar}/>
                                <span>{proj.stars}</span>
                                </span>
                                <span className='recent-time'>
                                    {dayjs().from(dayjs(proj.lastCommit), true)} ago
                                </span>
                            </div>
                            <CategoryTags categories={proj.categories}/>
                        </div>
                    })}
                </div>
            </div>
            <div className='random'>
                <h2 className='home-title'>
                    Random
                </h2>
                <div>
                    {data.random.map(proj => {
                            return <Project project={proj}/>
                        }
                    )}
                </div>
            </div>
            <div className='popular'>
                <h2 className='home-title'>
                    Popular
                </h2>
                <div>
                    {data.popular.map(proj => {
                            return <Project project={proj}/>
                        }
                    )}
                </div>
            </div>
            <div className='new'>
                <h2 className='home-title'>
                    New
                </h2>
                <div>
                    {data.new.map(proj => {
                            return <Project project={proj}/>
                        }
                    )}
                </div>
            </div>
            {/*<div className='trending'>*/}
            {/*    <h2 className='home-title'>*/}
            {/*        Trending*/}
            {/*    </h2>*/}
            {/*    <div>*/}
            {/*        {data.popular.map(proj => {*/}
            {/*                return <Project project={proj}/>*/}
            {/*            }*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    }

    menuOpen() {
        this.props.menuOpen()
    }

    getData() {
        NProgress.start();
        axios.get(config.api + '/home')
            .then((resp) => {
                console.log(resp.data)
                this.setState({
                    data: resp.data,
                    loaded: true
                });
                NProgress.done();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    /*
    componentDidUpdate(prevProps) {
        if (this.props.match.params.projectID !== prevProps.match.params.projectID) {
            this.getData();
        }
        window.dispatchEvent(new Event('scroll'))
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.state !== nextState ||
            this.props.match.params.projectID !== nextProps.match.params.projectID ||
            this.props.mainCategory !== nextProps.mainCategory
        )
        // return true;
        // return this.props.match.params.listingID !== nextProps.match.params.listingID;
    }
*/
    componentDidMount() {
        this.LoadingBar = React.createRef();
        this.getData();
    }

    render() {
        let homeClass = classNames([{
            // 'is-hidden': !this.props.match.params.projectID
        }, 'home'])
        return <div className={homeClass}>
            <div className="listing-heading mobile-show">
                <h1><a href={'#'} onClick={this.menuOpen.bind(this)} aria-label="Menu"><FontAwesomeIcon
                    icon={faBars}/></a>Home</h1>
            </div>
            {this.renderProject()}
        </div>
    }
}

export default Home;
